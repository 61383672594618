html, body {
    height: 100%;
}

._content {
    /*font-family: Verdana, sans-serif;*/
    flex: 1 0 auto;
}

hr {
    margin-top: 15px;
    margin-bottom: 15px;
}

body {
    display: flex;
    flex-direction: column;
}

video{
    max-width: 100%;
}

.main_row {
    display: flex;
    flex-direction: row;
}

.main {
    float: none;
    display: table-cell;
    vertical-align: top;
}

.sidebar-right {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.sidebar-last-topics {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;

    margin-bottom: 20px;
    margin-top: 10px;
    margin-right: 5px;

    background-color: #fff;

    border-color: #E0E0E0;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;

    /*overflow: auto;*/
    /*height: 300px;*/
}

.sidebar-advertisements {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar-wrapper {
    float: none;
    display: table-cell;
    vertical-align: top;
}

.footer {
    margin-top: 20px;
}

.footer .navbar {
    margin-bottom: 0;
}

img {
    max-width: 100%;
    height: auto;
}

iframe:not([width]) {
    width: 100%;
    max-width: 100%;
}

td {
    padding: 1px 4px;
    vertical-align: top;
}

.parent {
    display: flex;
}

.left {
    max-width: 30%;
}

.right {
    max-width: 70%;
}


ul.hr {
    margin: 0;
    padding: 8px;
}

ul.hr li {
    display: inline;
    margin-right: 15px;
}

ul.hr li a {
    color: #9d9d9d;
}

ul.hr li a.btn {
    color: #fff;
}

.navbar-custom {
    border-radius: 0;
    background-color: rgb(234, 233, 218);
    color: #7b7b7b;
}

.navbar-custom .navbar-nav > li > a {
    color: #7a7a7a;
}

.navbar-custom .navbar-nav > .active > a {
    background-color: transparent;
    color: #ffffff;
}

.navbar-custom .navbar-nav > li > a:hover,
.navbar-custom .navbar-nav > li > a:focus,
.navbar-custom .navbar-nav > .active > a:hover,
.navbar-custom .navbar-nav > .active > a:focus,
.navbar-custom .navbar-nav > .open > a {
    background-color: rgb(234, 233, 218);
    text-decoration: none;
}

.navbar-custom .navbar-brand {
    color: #7c7c7c;
}

.navbar-custom .navbar-toggle {
    background-color: #eeeeee;
}

.navbar-custom .icon-bar {
    background-color: rgb(234, 233, 218);
}

/* Sidebar navigation */
.nav-sidebar {
    display: flex;
    flex-direction: column;
}

.nav-sidebar a {
    display: block;
    padding: 10px 20px;
}

.nav-sidebar a:hover {
    background-color: #eee;
    text-decoration: none;
}

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
    background-color: #428bca;
    color: #fff;
}

body {
    padding-top: 54px;
}

@media (min-width: 768px) {
    .nav > li > a {
        padding-right: 8px;
        padding-left: 8px;
    }
}

@media (min-width: 823px) {
    .nav > li > a {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.row ._no-padding {
    padding-right: 0;
    padding-left: 0;
}

.treeview .list-group {
    margin-bottom: 0;
}

.list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.sidebar {
    position: fixed;
    top: 54px;
    z-index: 1000;
    overflow: hidden;
    padding: 0;
    width: 0;
    height: 100%;
    background-color: #f5f5f5;
}

.__sidebar:not(:nth-of-type(1)) {
    margin-top: auto;
}

.__in_house_sidebar {
    margin-bottom: auto;
}

@media (min-width: 768px) {
    .sidebar {
        top: 104px;
        bottom: 0;
        left: 0;
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        width: initial;
        border-right: 1px solid #eee;
    }

    .sidebar-collapse {
        display: none;
    }

    .sidebar-nav .navbar ul {
        flex-direction: column;
    }
}

.breadcrumb > li + li:before {
    padding: 0 5px !important;
    color: #707070 !important;
    content: ">\00a0" !important;
}

.breadcrumb > li {
    color: #337ab7;
}

.transcription-list {
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eceeef;
    border-left: 1px solid #eceeef;
    flex-wrap: wrap;
    align-items: stretch;
}

.transcription {
    display: flex;
    border-top: 1px solid #eceeef;
    border-right: 1px solid #eceeef;
    flex-grow: 1;
    flex-wrap: nowrap;
}

.transcription-word {
    padding: .75rem;
    width: 50%;
}

.nav.nav-tabs {
    margin-bottom: 1em;
}

.category h1 {
    border-radius: 5px;
    font-weight: 400;
    font-size: 1.2em
}

th.forum-topic-count, th.forum-post-count, th.topic-post-count, th.topic-view-count {
    text-align: center
}

td.topic-name {
    line-height: 16px
}

.topic-name-header {
    display: inline-block;
    margin: 0 4px 3px 0;
    line-height: 22px
}

td.forum-topic-count, td.forum-post-count, td.topic-post-count, td.topic-view-count {
    width: 10%;
    text-align: center;
    font-size: .8em
}

td.forum-last-post, td.topic-last-post {
    width: 25%;
    font-size: .8em
}

.forum h1 {
    border-radius: 5px;
    font-weight: 400;
    font-size: 1.3em
}

.forum-description {
    font-size: .8em
}

.forum-unread a, .topic-unread a {
    font-weight: bold
}

.post-info {
    width: 150px;
    font-size: .8em
}

.post-extra-info {
    font-size: .8em
}

.post-signature {
    overflow: auto;
    margin-top: 10px;
    padding-top: 10px;
    max-height: 40px;
    border-top: 1px dashed;
    font-size: .8em
}

.post-date {
    font-size: .8em
}

.post-content {
    max-width: 1033px;
    word-wrap: break-word
}

.post-control {
    float: right
}

.post-control-delete {
    margin-right: 24px
}

.post-control a {
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px
}

.topic-list, .category-table, .child-forums-table {
    border: 1px solid #ddd
}

.topic-list > thead > tr > th, .category-table > thead > tr > th, .topic-list > tbody > tr > th, .category-table > tbody > tr > th, .topic-list > tfoot > tr > th, .category-table > tfoot > tr > th, .topic-list > thead > tr > td, .category-table > thead > tr > td, .topic-list > tbody > tr > td, .category-table > tbody > tr > td, .topic-list > tfoot > tr > td, .category-table > tfoot > tr > td {
    border: 1px solid #ddd
}

.topic-list > thead > tr > th, .category-table > thead > tr > th, .topic-list > thead > tr > td, .category-table > thead > tr > td {
    border-bottom-width: 2px
}

.topic-list > tbody > tr:nth-child(odd) > td, .category-table > tbody > tr:nth-child(odd) > td, .topic-list > tbody > tr:nth-child(odd) > th, .category-table > tbody > tr:nth-child(odd) > th {
    background-color: #f9f9f9
}

.topic-list .forum-topic-count, .category-table .forum-topic-count, .topic-list .forum-post-count, .category-table .forum-post-count, .topic-list .forum-last-post, .category-table .forum-last-post, .topic-list .topic-post-count, .category-table .topic-post-count, .topic-list .topic-view-count, .category-table .topic-view-count, .topic-list .topic-last-post, .category-table .topic-last-post {
    vertical-align: middle
}

.topic-meta {
    font-size: .8em
}

.category h1 {
    color: #08c
}

.forum h1 {
    color: #08c
}

.forum-description {
    color: #777
}

.head-row {
    color: #777
}

.forum-unread a, .topic-unread a {
    font-weight: bold
}

.state-indicator, .state-indicator {
    float: left;
    margin: 5px 5px 0 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #999
}

.forum-unread .state-indicator, .topic-unread .state-indicator {
    background-color: #07b
}

.post-info {
    background: #fdfdfd
}

.post-extra-info {
    color: #999
}

.post-signature {
    border-top: 1px dashed #ddd;
    color: #666
}

.post-form input[type="text"], .post-form input[type="password"], .post-form textarea {
    width: 100%
}

.post thead {
    background: #f5f5f5
}

.post tfoot {
    background: #fcfcfc
}

.post-author {
    font-weight: bold
}

.post-info {
    color: #999
}

.post-date {
    color: #999
}

.post-control a {
    color: #6d6d6d
}

.post-control a:hover {
    background: #eaeaea
}

.topic h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #08c;
    font-weight: 400;
    font-size: 1.3em;
    line-height: 1.1;
}

.updated {
    color: #999;
    font-size: .8em;
}

.updated-message {
    color: #999;
    text-align: right;
    font-size: .8em;
}

.sidebar-collapse-button {
    display: none;
}

@media (max-width: 767px) {
    .sidebar-wrapper {
        position: fixed;
        display: none;
    }

    .sidebar-wrapper.active {
        z-index: 999;
        display: initial;
        position: fixed;
        width: 100%;
        height: 92%;
        overflow: scroll;
        padding-right: 20%;
    }

    .sidebar-collapse-button {
        display: inline;
        position: fixed;
        top: 70px;
        left: 0;
        z-index: 999;
        padding: 5px;
        border: 1px solid #bbb7b7;
        border-left: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background:#f5f5f5ba;
        box-shadow: -1px 10px 16px rgb(0 0 0 / 30%), 0 0 1px 0 rgb(0 20 51 / 40%);
    }

    .sidebar-collapse-button .arrow-icon {
        width: 15px;
        height: 22px;
        background: url("/static/images/chevron-right.png");
        background-repeat: no-repeat;
        display: block;
    }

    .sidebar-collapse-button[aria-expanded="true"] {
        left: 80%;
    }

    .sidebar-collapse-button[aria-expanded="true"] .arrow-icon {
        background: url("/static/images/chevron-left.png");
    }

    .sidebar-collapse-button > div {
        overflow: hidden;
        position: relative;
        float: right;
        height: 34px;
        padding-top: 10px;
        margin-top: -10px;
    }

    .sidebar-collapse-button > div li {
        font-family: Serif, serif;
        color: #eee;
        font-weight: 700;
        padding: 0 10px;
        height: 45px;
        margin-bottom: 45px;
        display: block;
    }

    .flip2 {
        -webkit-animation: flip2 120s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
        animation: flip2 120s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
        padding-left: 0;
    }

    @-webkit-keyframes flip2 {
        0% {
            margin-top: -180px;
        }
        5% {
            margin-top: -90px;
        }
        50% {
            margin-top: -90px;
        }
        55% {
            margin-top: 0;
        }
        99.99% {
            margin-top: 0;
        }
        100% {
            margin-top: -270px;
        }
    }

    @keyframes flip2 {
        0% {
            margin-top: -180px;
        }
        5% {
            margin-top: -90px;
        }
        50% {
            margin-top: -90px;
        }
        55% {
            margin-top: 0;
        }
        99.99% {
            margin-top: 0;
        }
        100% {
            margin-top: -270px;
        }
    }

}

.navbar-brand-text {
    font-size: 14px;
}

.navbar-brand-home {
    display: none;
    margin-left: 10%;
    margin-right: 10%;
}

.navbar-brand-home:hover {
    color: #fff;
}

@media (max-width: 992px) {
    /*._content {*/
    /*margin-top: 50px;*/
    /*}*/
    .navbar-brand-home {
        display: inline;
    }

    .navbar-brand-text {
        display: none;
    }
}

@media (max-width: 767px) {
    ._content {
        margin-top: 0;
    }

    .navbar-brand-home {
        display: none;
        margin-left: 10%;
        margin-right: 10%;
        padding: 15px 5px;
    }

    .navbar-brand-text {
        font-size: 18px;
        display: inline;
    }
}

@media (max-width: 450px) {
    ._content {
        margin-top: 0;
    }

    .navbar {
        padding: 0.5rem 0.5rem;
    }

    .navbar-brand-home {
        display: inline;
        margin-left: 10%;
        margin-right: 0;
    }

    .navbar-brand-text {
        font-size: 18px;
        display: none;
    }
}

.change-username-form {
    margin-top: 50px;
}

.top-buffer {
    margin-top: 20px;
}

.media-heading {
    font-size: 11px;
    color: #777;
}

.comment {
    margin-top: 1em;
}

.advert_bottom {
    position: absolute;
    bottom: 0px;
    padding: 0 0 40px;
}

.inline-ads {
    text-align: center;
    margin: 1em 0em;
}

.inline-ads-mobile {
    text-align: center;
}

.alignleft {
    display: inline;
    float: left;
    margin-right: 1em;
}

.first_row {
    padding-top: 5px;
}

.cofee-icon {

}

.coffee-button {
    margin-bottom: 10px;
    margin-top: 10px;
}

.popover-content {
    background-color: #fdf9ca;
    z-index: 999;
}

.faded::after {
    content: '';
    display: block;
    position: relative;
    bottom: 150px;
    margin-bottom: -150px;
    left: 0;
    width: 100%;
    height: 150px;
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(bottom, #fff, rgba(255, 255, 255, 0));
    background: -moz-linear-gradient(bottom, #fff, rgba(255, 255, 255, 0));
    background: -o-linear-gradient(bottom, #fff, rgba(255, 255, 255, 0));
    background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
}

.arnaud-comment {
    position: relative;
    padding: 0 4px;
    cursor: pointer;
    white-space: pre-wrap;
    border-radius: 4px;
    background: #fff0b4;
    box-shadow: inset 1px 0 #dcdcdc, inset 0 1px #dcdcdc, inset -1px 0 #dcdcdc, inset 0 -1px #dcdcdc;
}

.arnaud-comment:hover {
    background: #ffe168;
    box-shadow: inset 1px 0 #999, inset 0 1px #999, inset -1px 0 #999, inset 0 -1px #999
}

/* Details Styles */
details {
    display: block;
    margin-bottom: 1em;
}

details[open] > summary::before {
    content: "\25BC";
}

details summary {
    display: block;
    cursor: pointer;
}

details summary:focus {
    outline: none;
}

details summary::before {
    content: "\25B6";
    font-family: none;
    padding-right: 0.5em;
}

details summary::-webkit-details-marker {
    display: none;
}

/* Attach the "no-details" class to details tags
   in browsers that do not support them to get
   open/show functionality. */
details.no-details:not([open]) > * {
    display: none;
}

details.no-details:not([open]) summary {
    display: block;
}

.coffee-icon > svg {
    display: inline;
    height: 20px;
    width: 20px;
}

.coffee-icon > svg:hover {
    fill: #fff;
}

.coffee-icon > svg:focus {
    fill: #fff;
}

.coffee-icon > svg:active {
    fill: #fff;
}

.fa-tshirt {
    font-size: 16px;
    vertical-align: middle;
}

@media (max-width: 1000px) {
    .navbar > .container {
        width: 100%;
    }
}

.navbar-nav > li > a {
    /*padding: 15px 10px;*/
}

.navbar > .conteiner {
    padding-right: 10px;
    padding-left: 10px;
}

.username {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 106px;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
}

.bottom-message {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    min-height: 21px;
    z-index: 1030;
    background: hsla(208, 56%, 93%, 1);
    color: rgb(51, 51, 51);
    line-height: 21px;
    padding: 8px 18px;
    font-family: verdana, arial, sans-serif;
    font-size: 14px;
    /*text-align: center;*/
    opacity: 1;
    border-top: #337ab7 solid 2px;
    /*transition: height 0.5s ease-out;*/
}


.bottom-message-content {
    max-width: 1160px;
    margin: 0 auto;
}

.bottom-message-button-close {
    color: #666c7d;
    float: right;
    display: block;
    padding: 0 4px;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #666c7d6e;
}

.bottom-message-text {
    display: block;
    padding: 5px 0 5px 0;
}

.bottom-message-text p {
    margin-bottom: 0.6rem;
}

.bottom-message-buttons {
    text-align: center;
}

.bottom-message-button {
    margin-top: 10px;
    padding: 3px 13px 3px 13px;
}

.bottom-message-popover > ul {
    padding-left: 24px;
}

.bottom-message-popover > p {
    padding-left: 6px
}

/*@media (min-width: 500px) {*/
/*.bottom-message-buttons{*/
/*text-align: left;*/
/*}*/
/*}*/
.nav-mobile-text {

}

.navbar-sticky {
    float: left;
}

.navbar-sticky > ul > li > a {
    color: #9d9d9d;
    padding: 15px 10px;
}

.navbar-sticky > ul > li > a:hover {
    color: #fff;
    background-color: transparent;
}

.navbar-sticky > ul > li > a:focus {
    background-color: transparent;
    outline: -webkit-focus-ring-color auto 5px;
}

.navbar-sticky > ul > li.open > a {
    color: #fff;
    background-color: #080808 !important;
}

@media (max-width: 822px) {
    .navbar-sticky > ul > li > a {
        padding: 15px 5px;
    }
}

@media (max-width: 767px) {
    .navbar-sticky {
        float: right;
    }

    .navbar-sticky > ul > li > a {
        padding: 10px 8px;
    }

    .navbar-sticky-ul {
        margin-right: 15px;
        margin-top: 4px;
    }
}

@media (min-width: 768px) {
    .navbar {
        border-radius: 0px;
    }
}

.grecaptcha-badge {
    visibility: collapse !important;
}

.fa-ticket-alt {
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.lesson-tabs > .nav-item > .nav-link, .conjugation-tabs > .nav-item > .nav-link {
    border-color: #dee2e6 #dee2e6 #fff;
}

.lesson-tabs > .nav-item > .nav-link.active, .conjugation-tabs > .nav-item > .nav-link.active {
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link {
    margin-bottom: 0;
    margin-top: 3px;
}

.nav-tabs > .nav-item > .nav-link.active {
    margin-top: -6px;
    height: 3em;
}

.lesson-audio {
    align-items: center;
}

.block-audio {
    margin-bottom: 1em;
}

.modal-footer {
    display: block;
}


.d-flex .flex-row > .navbar-nav .flex-row > .dropdown-toggle::after {
    vertical-align: 0;
}

.h1-small {
    font-size: 1.725rem;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.leftimg {
    float: left; /* Выравнивание по левому краю */
    margin: 5px 15px 0 0; /* Отступы вокруг картинки */
}

.rightimg {
    float: right; /* Выравнивание по правому краю  */
    margin: 5px 0 0 10px; /* Отступы вокруг картинки */
}

.block_widescreen {
    display: inherit;
}

.block_smallscreen {
    display: none;
}

@media (max-width: 520px) {

    .block_widescreen {
        display: none;
    }

    .block_smallscreen {
        display: inherit;
        width: 100%;
    }
}

button.collapsed{
    content: "Свернуть";
}

.collapse-arrow:after{
    color: var(--secondary);
    content: "\f062";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: small;
}

.collapsed.collapse-arrow:after{
    color: var(--secondary);
    content: "\f063";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: small;
}



	.bs-callout {
		padding: 15px;
		border: 1px solid #eee;
		border-left-width: 5px;
		border-radius: 4px;
		margin: 10px 0 10px 0px;
                .shadow-sm
                .rounded
	}

	.bs-callout-warning {
		border-left-color: var(--warning);
	// background-color: #fefbed;
	}
	.bs-callout-warning h4 {
		color: var(--warning);
        background-color: #fefbedab;
	}
	.bs-callout-success {
		border-left-color: var(--success);
	// background-color: #efffe8;
	}

	.bs-callout-success h4 {
		color: var(--success);
	}

	.bs-callout-secondary {
		border-left-color: var(--secondary);
		background-color: #e9ecef;
	}
	.bs-callout-secondary h4 {
		color: var(--secondary);
	}

	.bs-callout-primary {
		border-left-color: var(--primary);
	}
	.bs-callout-primary h4 {
		color: var(--primary);
	}

    .bs-callout-info {
        border-left-color: var(--info);
    }
    .bs-callout-info h4 {
        color: var(--info)
    }

	.bs-callout.disabled {
		opacity: 0.4;
	}

	.table-responsive.no_margin_p p {
    margin: 0.2rem;
	}

a.no-color {
    color: inherit;
}

a.no-decoration {
    text-decoration: inherit;
}

.page-reviews {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.page-review {
    padding: 3px;
}

a.page-review:focus,
a.page-review:hover {
    text-decoration: none;
}

.page-review {
    width: 27%;
    margin-top: 0px;
    margin-right: 6%;
    margin-bottom: 0px;
    min-height: 70px;
}

@media (max-width: 768px) {
    .page-review {
        margin: 10px 0;
        width: 100%;
    }

}

.page-review_text {
    margin-top: 0px;
    color: #495869;
    font-size: 16px;
}

.page-review_text:hover {
    color: #232937;
}

.page-review_text:before {
    color: #bbb;
    content: "\201C";
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 4em;
    line-height: .1em;
    margin-right: .2em;
    vertical-align: -.4em;
    margin-left: -20px;
}

.page-review_text:after {
    color: #bbb;
    content: "\201D";
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 4em;
    line-height: .1em;
    float: right;
    top: .45em;
    margin-right: -15px;
}

@media (max-width: 575px) {
    .page-review_text:after, .page-review_text:before {
        display: none;

    }

}

.page-reviews .carousel-control-prev {
 filter: invert(50%);
    top: initial !important;
}

.page-reviews .carousel-control-next {
  filter: invert(50%);
    top: initial !important;
}

.page-reviews .carousel-indicators {
    filter: invert(50%);
    bottom: -17px;
}

.page-reviews .carousel {
    width: 100% !important;
}

.page-reviews .carousel-indicators > li {
    height: 2px;
    width: 2px;
}

#carouselReviewsOuter{
    width: 100%;
    transition: 0.6s ease-in-out;
    height: 0;
    overflow: hidden;
}

#playerPlus p {
    margin-bottom: 0.8rem;
}

#playerPlus .h1,#playerPlus .h2,#playerPlus .h3,#playerPlus .h4,#playerPlus .h5,#playerPlus .h6{
    line-height: 2;
}

i.play {
    cursor: pointer;
    color: gray;
    position: relative;
    top: 1px;
}

span.underline.play {
    cursor: pointer;
    background: url("../svg/underline_2.svg") repeat-x 0 100%;
    transition: 0.1s;
    padding-bottom: 1px;
    padding-left: 1px;
    padding-right: 1px;
}

span.underline.play:hover {
    background-image: url("../svg/underline_1.svg");
}

span.underline.play:focus {
  background-image: none;
  outline: none;
  border-radius: 4px;
  background-color: #cbe7ff;
  box-shadow: 1px 1px 5px 0px;
}

span.underline.play *{
    pointer-events: none;
}

.btn-youtube {

}

.vcontainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.youtube-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.popover-body {
    font-size: 0.9rem;
}

.popover > .close {
    margin-bottom: 0;
    font-size: 1.37rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    padding-right: 12px;
}

.popover > .close {
    margin-bottom: 0;
    font-size: 1.37rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    padding-right: 12px;
}
/*
#topMenu > div > div > ul > li.nav-item.dropdown.show > div.dropdown-menu  {
    left: -59px
}

@media (min-width: 575px) {
    #topMenu > div > div > ul > li.nav-item.dropdown.show > div.dropdown-menu {
        left: 0
    }
}
*/

.text-orange {
    color: #f28b1a;
}

h1, h2, h3, h4 {
    margin-top: 1.2rem;
}